define("discourse/plugins/discourse-events/discourse/components/add-to-calendar", ["exports", "@ember/component", "@ember/runloop", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _component, _runloop, _decorators, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    expanded: false,
    classNames: "add-to-calendar",
    didInsertElement() {
      this._super(...arguments);
      $(document).on("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    willDestroyElement() {
      this._super(...arguments);
      $(document).off("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    outsideClick(e) {
      if (!this.isDestroying && !$(e.target).closest(".add-to-calendar").length) {
        this.set("expanded", false);
      }
    },
    calendarUris() {
      const topic = this.get("topic");
      let params = {
        event: topic.event,
        title: topic.title,
        url: window.location.hostname + topic.get("url")
      };
      if (topic.location && topic.location.geo_location) {
        params["location"] = topic.location.geo_location.address;
      }
      return [{
        uri: (0, _dateUtilities.googleUri)(params),
        label: "google"
      }, {
        uri: (0, _dateUtilities.icsUri)(params),
        label: "ics"
      }];
    },
    actions: {
      expand() {
        this.toggleProperty("expanded");
      }
    }
  }, [["method", "calendarUris", [(0, _decorators.default)("topic.event")]]]));
});