define("discourse/plugins/discourse-events/discourse/components/events-calendar-event", ["exports", "@ember/component", "@ember/object", "discourse/lib/url"], function (_exports, _component, _object, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "li",
    selectEvent(url) {
      event?.preventDefault();
      const responsive = this.get("responsive");
      if (responsive) {
        _url.default.routeTo(url);
      } else {
        this.toggleProperty("showEventCard");
      }
    }
  }, [["method", "selectEvent", [_object.action]]]));
});