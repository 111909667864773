define("discourse/plugins/discourse-events/discourse/components/events-provider-row", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/lib/events", "discourse/plugins/discourse-events/discourse/models/provider"], function (_exports, _component, _computed, _decorators, _events, _provider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TOKEN_TYPES = ["eventbrite", "humanitix", "eventzilla"];
  const NO_AUTH_TYPES = ["icalendar"];
  const OAUTH2_TYPES = ["meetup"];
  const PROVIDER_TYPES = [...NO_AUTH_TYPES, ...TOKEN_TYPES, ...OAUTH2_TYPES];
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "tr",
    classNames: ["events-provider-row"],
    attributeBindings: ["provider.id:data-provider-id"],
    hideCredentials: true,
    hasSecretCredentials: (0, _computed.or)("showToken", "showClientCredentials"),
    didReceiveAttrs() {
      this._super();
      this.set("currentProvider", JSON.parse(JSON.stringify(this.provider)));
    },
    providerChanged(name, url, type) {
      const cp = this.currentProvider;
      return cp.name !== name || cp.url !== url || cp.provider_type !== type;
    },
    saveDisabled(providerChanged) {
      return !providerChanged;
    },
    saveClass(providerChanged) {
      return providerChanged ? "save-provider btn-primary" : "save-provider";
    },
    providerTypes() {
      return (0, _events.contentsMap)(PROVIDER_TYPES);
    },
    authenticateDisabled(canAuthenicate, providerChanged, providerAuthenticated) {
      return !canAuthenicate || providerChanged || providerAuthenticated;
    },
    authenticateClass(authenticateDisabled) {
      return authenticateDisabled ? "" : "btn-primary";
    },
    canAuthenicate(providerType) {
      return providerType && OAUTH2_TYPES.includes(providerType);
    },
    showToken(providerType) {
      return providerType && TOKEN_TYPES.includes(providerType);
    },
    showNoAuth(providerType) {
      return !providerType || NO_AUTH_TYPES.includes(providerType);
    },
    showClientCredentials(providerType) {
      return providerType && OAUTH2_TYPES.includes(providerType);
    },
    actions: {
      toggleHideCredentials() {
        this.toggleProperty("hideCredentials");
      },
      saveProvider() {
        const provider = JSON.parse(JSON.stringify(this.provider));
        if (!provider.name) {
          return;
        }
        this.set("saving", true);
        _provider.default.update(provider).then(result => {
          if (result) {
            this.setProperties({
              currentProvider: result.provider,
              provider: _provider.default.create(result.provider)
            });
          } else if (this.currentSource.id !== "new") {
            this.set("provider", JSON.parse(JSON.stringify(this.currentProvider)));
          }
        }).finally(() => {
          this.set("saving", false);
        });
      },
      authenticateProvider() {
        window.location.href = `/admin/events/provider/${this.provider.id}/authorize`;
      }
    }
  }, [["method", "providerChanged", [(0, _decorators.default)("provider.name", "provider.url", "provider.provider_type")]], ["method", "saveDisabled", [(0, _decorators.default)("providerChanged")]], ["method", "saveClass", [(0, _decorators.default)("providerChanged")]], ["method", "providerTypes", [_decorators.default]], ["method", "authenticateDisabled", [(0, _decorators.default)("canAuthenicate", "providerChanged", "provider.authenticated")]], ["method", "authenticateClass", [(0, _decorators.default)("authenticateDisabled")]], ["method", "canAuthenicate", [(0, _decorators.default)("provider.provider_type")]], ["method", "showToken", [(0, _decorators.default)("provider.provider_type")]], ["method", "showNoAuth", [(0, _decorators.default)("provider.provider_type")]], ["method", "showClientCredentials", [(0, _decorators.default)("provider.provider_type")]]]));
});