define("discourse/plugins/discourse-events/discourse/components/events-calendar-body", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _component, _decorators, _I18n, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "events-calendar-body",
    expandedDate: 0.0,
    setup() {
      this._super();
      moment.locale(_I18n.default.locale);
    },
    weekdays(responsive) {
      let data = moment.localeData();
      let weekdays = $.extend([], responsive ? data.weekdaysMin() : data.weekdays());
      let firstDay = (0, _dateUtilities.firstDayOfWeek)();
      let beforeFirst = weekdays.splice(0, firstDay);
      weekdays.push(...beforeFirst);
      return weekdays;
    },
    resetExpandedDate() {
      this.set("expandedDate", null);
    },
    actions: {
      setExpandedDate(date) {
        event?.preventDefault();
        const month = this.get("currentMonth");
        this.set("expandedDate", month + "." + date);
      }
    }
  }, [["method", "setup", [(0, _decorators.on)("init")]], ["method", "weekdays", [(0, _decorators.default)("responsive")]], ["method", "resetExpandedDate", [(0, _decorators.observes)("currentMonth")]]]));
});