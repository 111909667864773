define("discourse/plugins/discourse-events/discourse/components/events-date-picker", ["exports", "@ember/runloop", "discourse/components/date-picker", "discourse/lib/load-script", "discourse-common/lib/object", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _runloop, _datePicker, _loadScript, _object, _decorators, _I18n, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _datePicker.default.extend(dt7948.p({
    layoutName: "components/date-picker",
    setDate() {
      if (this._picker && this.value) {
        this._picker.setDate(this.value);
      }
    },
    _loadDatePicker() {
      const input = this.element.querySelector(".date-picker");
      const container = document.getElementById(this.get("containerId"));
      (0, _loadScript.default)("/javascripts/pikaday.js").then(() => {
        (0, _runloop.next)(() => {
          let default_opts = {
            field: input,
            container: container || this.element,
            bound: container === undefined,
            format: "YYYY-MM-DD",
            firstDay: (0, _dateUtilities.firstDayOfWeek)(),
            i18n: {
              previousMonth: _I18n.default.t("dates.previous_month"),
              nextMonth: _I18n.default.t("dates.next_month"),
              months: moment.months(),
              weekdays: moment.weekdays(),
              weekdaysShort: moment.weekdaysShort()
            },
            onSelect: date => {
              const formattedDate = moment(date).format("YYYY-MM-DD");
              if (this.attrs.onSelect) {
                this.attrs.onSelect(formattedDate);
              }
              if (!this.element || this.isDestroying || this.isDestroyed) {
                return;
              }
              this.set("value", formattedDate);
            }
          };
          this._picker = new Pikaday((0, _object.deepMerge)(default_opts, this._opts())); // eslint-disable-line no-undef
        });
      });
    }
  }, [["method", "setDate", [(0, _decorators.observes)("value")]], ["method", "_loadDatePicker", [(0, _decorators.on)("didInsertElement")]]]));
});