define("discourse/plugins/discourse-events/discourse/components/events-header", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["events-header"],
    title(viewName) {
      return _I18n.default.t(`admin.events.${viewName}.title`);
    }
  }, [["method", "title", [(0, _decorators.default)("viewName")]]]));
});