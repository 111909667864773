define("discourse/plugins/discourse-events/discourse/components/events-calendar-day", ["exports", "@ember/component", "@ember/object/computed", "@ember/runloop", "@ember/template", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _component, _computed, _runloop, _template, _decorators, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_EVENTS = 4;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":day", "classes", "differentMonth"],
    attributeBindings: ["day:data-day"],
    hidden: 0,
    hasHidden: (0, _computed.gt)("hidden", 0),
    expanded(date, month, expandedDate) {
      return `${month}.${date}` === expandedDate;
    },
    differentMonth(month, currentMonth) {
      return month !== currentMonth;
    },
    setEvents() {
      const expanded = this.get("expanded");
      const allEvents = this.get("allEvents");
      let events = $.extend([], allEvents);
      if (events.length && !expanded) {
        let hidden = events.splice(MAX_EVENTS);
        if (hidden.length) {
          this.set("hidden", hidden.length);
        }
      } else {
        this.set("hidden", 0);
      }
      this.set("events", events);
    },
    allEvents(day, topics, expanded, rowIndex) {
      return (0, _dateUtilities.eventsForDay)(day, topics, {
        rowIndex,
        expanded,
        siteSettings: this.siteSettings
      });
    },
    rowIndex(index) {
      return index % 7;
    },
    didInsertElement() {
      this._super(...arguments);
      this.set("clickHandler", (0, _runloop.bind)(this, this.documentClick));
      $(document).on("click", this.get("clickHandler"));
    },
    willDestroyElement() {
      this._super(...arguments);
      $(document).off("click", this.get("clickHandler"));
    },
    documentClick(event) {
      if (!event.target.closest(`.events-calendar-body .day[data-day='${this.day}']`)) {
        this.clickOutside();
      } else {
        this.click();
      }
    },
    clickOutside() {
      if (this.get("expanded")) {
        this.get("setExpandedDate")(null);
      }
    },
    click() {
      const canSelectDate = this.get("canSelectDate");
      if (canSelectDate) {
        const date = this.get("date");
        const month = this.get("month");
        this.selectDate(date, month);
      }
    },
    date() {
      const day = this.get("day");
      return day.date();
    },
    month() {
      const day = this.get("day");
      return day.month();
    },
    classes(day, currentDate, currentMonth, expanded, responsive) {
      let classes = "";
      if (day.isSame(moment(), "day")) {
        classes += "today ";
      }
      if (responsive && day.isSame(moment().month(currentMonth).date(currentDate), "day")) {
        classes += "selected ";
      }
      if (expanded) {
        classes += "expanded";
      }
      return classes;
    },
    containerStyle(expanded) {
      let style = "";
      if (expanded) {
        const offsetLeft = this.element.offsetLeft;
        const offsetTop = this.element.offsetTop;
        const windowWidth = $(window).width();
        const windowHeight = $(window).height();
        if (offsetLeft > windowWidth / 2) {
          style += "right:0;";
        } else {
          style += "left:0;";
        }
        if (offsetTop > windowHeight / 2) {
          style += "bottom:0;";
        } else {
          style += "top:0;";
        }
      }
      return (0, _template.htmlSafe)(style);
    }
  }, [["method", "expanded", [(0, _decorators.default)("date", "month", "expandedDate")]], ["method", "differentMonth", [(0, _decorators.default)("month", "currentMonth")]], ["method", "setEvents", [(0, _decorators.observes)("expanded"), (0, _decorators.on)("init")]], ["method", "allEvents", [(0, _decorators.default)("day", "topics.[]", "expanded", "rowIndex")]], ["method", "rowIndex", [(0, _decorators.default)("index")]], ["method", "date", [(0, _decorators.default)("index")]], ["method", "month", [(0, _decorators.default)("index")]], ["method", "classes", [(0, _decorators.default)("day", "currentDate", "currentMonth", "expanded", "responsive")]], ["method", "containerStyle", [(0, _decorators.default)("expanded")]]]));
});