define("discourse/plugins/discourse-events/discourse/components/events-event-row", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "tr",
    classNameBindings: [":events-event-row", "showSelect", "selected"],
    selected: false,
    toggleWhenShowSelect() {
      if (!this.showSelect) {
        this.set("selected", false);
      }
    },
    toggleWhenSelectAll() {
      this.set("selected", this.selectAll);
    },
    click() {
      if (this.showSelect) {
        this.selectEvent();
      }
    },
    selectEvent() {
      this.toggleProperty("selected");
      this.modifySelection([this.event], this.selected);
    },
    actions: {
      selectEvent() {
        this.selectEvent();
      }
    }
  }, [["method", "toggleWhenShowSelect", [(0, _decorators.observes)("showSelect")]], ["method", "toggleWhenSelectAll", [(0, _decorators.observes)("selectAll")]]]));
});